export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  nombre_local:'usuario_pos_agil',
  api_backend: 'https://backend-pos-agil.cervezapuertolibre.cl/api-posagil',
  servidor_socket:'https://api-impresion.cervezapuertolibre.cl'
  // servidor_socket:'http://localhost:3030'
  // servidor_socket:'http://impresion-dos.waliex.com'
};

