import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertaService } from '../services/alertas/alerta.service';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class InicioSesionGuard implements CanActivate {
  constructor(
    private router: Router, private _jwtSrv: JwtService,
    private _alertaSrv: AlertaService
  ) { }

  canActivate() {
    if (!this._jwtSrv.islogged()) {
      this._jwtSrv.eliminarToken()
      this._alertaSrv.alertaErrorMsj('Tiempo de sesión expirado, por favor ingrese nuevamente')
      this.router.navigateByUrl('/')
      return false
    }
    return true
  }

  canActivateChild() {
    return this.canActivate()
  }
}
