import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class NoInicioSesionGuard implements CanActivate {
  constructor(private router: Router, private _jwtSrv: JwtService) { }

  canActivate() {
    if (this._jwtSrv.islogged()) {
      this.router.navigateByUrl('/inicio')
      return false
    }
    return true
  }
}
