import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id:1,
        label:'MENUITEMS.INICIO.TEXT',
        icon: 'bx-home',
        link: '/inicio',
    },
    {
        id:2,
        label:'MENUITEMS.REPORTE.TEXT',
        icon: 'bx bxs-report',
        link: '/gestion/reporte/selecion-reporte-bodega',
    },
    {
        id:3,
        label:'MENUITEMS.DATOEMPRESA.TEXT',
        icon: 'bx-buildings',
        link: '/gestion/empresa',
    },
    {
        id:4,
        label:'MENUITEMS.EGRESO.TEXT',
        icon: 'bx bx-dollar',
        link: '/gestion/egreso/ver-egresos',
    },
    {
        id:5,
        label:'MENUITEMS.VENTATITULO.TEXT',
        isTitle:true
    },
    {
        id:6,
        label:'MENUITEMS.CREARVENTA.TEXT',
        icon:'bxs-store',
        link:'/gestion/venta/crear-venta'
    },
    {
        id:7,
        label:'MENUITEMS.VENTA.TEXT',
        icon:'bx-receipt',
        link:'/gestion/venta/ver-ventas'
    },
    {
        id:8,
        label:'MENUITEMS.GESTION.TEXT',
        isTitle:true
    },
    {
        id:9,
        label:'MENUITEMS.USUARIO.TEXT',
        icon: 'bx-user-circle',
        link: '/gestion/usuario/ver-usuarios',
    },
    {
        id:10,
        label:'MENUITEMS.CLIENTE.TEXT',
        icon: 'bxs-user-rectangle',
        link: '/gestion/cliente/ver-clientes',
    },
    {
        id:11,
        label:'MENUITEMS.BODEGA.TEXT',
        icon: 'bx-bold',
        link: '/gestion/bodega/ver-bodegas',
    },
    {
        id:12,
        label:'MENUITEMS.CATEGORIA.TEXT',
        icon: 'bx-cube-alt',
        link: '/gestion/categoria/ver-categorias',
    },
    {
        id:13,
        label:'MENUITEMS.PRODUCTO.TEXT',
        icon: 'bxl-product-hunt',
        link: '/gestion/producto/ver-productos',
    },
];

