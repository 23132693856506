import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  variable_local = environment.nombre_local;
  token:string;

  constructor() { }

  //GUARDAR TOKEN
  guardarToken(token){
    localStorage.setItem(this.variable_local,token)
  }

  //OBTENER TOKEN
  obtenerToken(){
    this.token = localStorage.getItem(this.variable_local)
    return this.token
  }

  //ELIMINAR TOKEN
  eliminarToken(){
    localStorage.removeItem(this.variable_local);
    this.token = '';
  }

  //COMPROBAR DURACION TOKEN GUARDADO EN LOCALSTORAGE
  islogged(){
    let datos = this.obtenerDatos()
    if(datos){
      return datos.exp > Date.now()/1000
    }else{
      return false;
    }
  }

  //OBTENER LOS DATOS DEL TOKEN
  obtenerDatos(){
    let token = this.obtenerToken();
    if(token){
      let payload
      payload = token.split('.')[1]
      payload = window.atob(payload)
      return JSON.parse(payload)
    }else{
      return null;
    }
  }

  //VALIDAR TOKEN PROVINIENTE DE URL
  verificarTokenUrl(token_url){
    if(token_url){
      let payload
      payload = token_url.split('.')[1]
      payload = window.atob(payload)
      let datos = JSON.parse(payload)
      if(datos){
        return datos.exp > Date.now()/1000
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

}
