import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import swal from 'sweetalert2';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class AlertaService {

  constructor(private toastr: ToastrService) { }

  //MOSTRAR MODAL LOADER
  loading(){
    swal.fire({
      title:'Cargando...',
      allowOutsideClick:false,//Evitar cerrar el modal clikeando afuera
      allowEscapeKey:false, // Evita cerrar el modal con el "esc"
      didOpen: () => {
        swal.showLoading()
      }
    });
  }

  //OCULTAR MODAL
  cerrarAlerta(){
    swal.close();
  }

  //ALERTA DE EXITO CON MENSAJE
  alertaExitoMsj(mensaje,texto?){
    swal.fire({
      title: mensaje,
      text: texto,
      buttonsStyling: false,
      allowOutsideClick:false,//Para no cerrar el modelo con click al exterior
      allowEscapeKey:false, //Para no cerrar el modelo con esc
      customClass:{
        confirmButton: "btn btn-success",
      },
      confirmButtonText: 'Ok',
      icon: "success"
    });
  }

  //ALERTA DE ERROR GENERICO
  alertaError(){
    swal.fire({
      title: '¡Ha ocurrido un problema!',
      buttonsStyling: false,
      allowOutsideClick:false,//Para no cerrar el modelo con click al exterior
      allowEscapeKey:false, //Para no cerrar el modelo con esc
      customClass:{
        confirmButton: "btn btn-success",
      },
      confirmButtonText: 'Ok',
      icon: "error"
    });
  }
  //ALERTA DE ERROR CON MENSAJE
  alertaErrorMsj(mensaje,texto?){
    swal.fire({
      title: mensaje,
      text: texto,
      buttonsStyling: false,
      allowOutsideClick:false,//Para no cerrar el modelo con click al exterior
      allowEscapeKey:false, //Para no cerrar el modelo con esc
      customClass:{
        confirmButton: "btn btn-success",
      },
      confirmButtonText: 'Ok',
      icon: "error"
    });
  }

  alertaErrorMsjError(mensaje,texto?){
    swal.fire({
      title: mensaje,
      text: texto,
      buttonsStyling: false,
      allowOutsideClick:false,//Para no cerrar el modelo con click al exterior
      allowEscapeKey:false, //Para no cerrar el modelo con esc
      customClass:{
        confirmButton: "btn btn-error",
      },
      confirmButtonText: 'Ok',
      icon: "error"
    });
  }

  notificacionExito(titulo:string,mensaje:string){
    this.toastr.success(mensaje,titulo,{timeOut:1500, progressBar:true, positionClass:'toast-top-center'})
  }

  notificacionError(titulo:string,mensaje:string){
    this.toastr.error(mensaje,titulo,{timeOut:1500, progressBar:true, positionClass:'toast-top-center'})
  }

  notificacionAlerta(titulo:string,mensaje:string){
    this.toastr.warning(mensaje,titulo,{timeOut:1500, progressBar:true, positionClass:'toast-top-center'})
  }

  nofiticacionInfo(titulo:string,mensaje:string){
    this.toastr.info(mensaje,titulo,{timeOut:1500, progressBar:true, positionClass:'toast-top-center'})
  }

}
