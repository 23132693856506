import { MenuItem } from './menu.model';

export const MENUVENDEDOR: MenuItem[] = [
    {
        id:300,
        label:'MENUITEMS.INICIO.TEXT',
        icon: 'bx-home',
        link: '/inicio',
    },
    // {
    //     id:1000,
    //     label:'MENUITEMS.REPORTE.TEXT',
    //     icon: 'bx bxs-report',
    //     link: '/gestion/reporte/selecion-reporte-bodega',
    // },
    // {
    //     id:301,
    //     label:'MENUITEMS.DATOEMPRESA.TEXT',
    //     icon: 'bx-buildings',
    //     link: '/gestion/empresa',
    // },
    {
        id:302,
        label:'MENUITEMS.EGRESO.TEXT',
        icon: 'bx bx-dollar',
        link: '/gestion/egreso/ver-egresos',
    },
    {
        id:207,
        label:'MENUITEMS.VENTATITULO.TEXT',
        isTitle:true
    },
    {
        id:208,
        label:'MENUITEMS.CREARVENTA.TEXT',
        icon:'bxs-store',
        link:'/gestion/venta/vendedor-crear-venta'
    },
    {
        id:209,
        label:'MENUITEMS.VENTA.TEXT',
        icon:'bx-receipt',
        link:'/gestion/venta/ver-ventas'
    },
    {
        id:201,
        label:'MENUITEMS.GESTION.TEXT',
        isTitle:true
    },
    // {
    //     id:202,
    //     label:'MENUITEMS.USUARIO.TEXT',
    //     icon: 'bx-user-circle',
    //     link: '/gestion/usuario/ver-usuarios',
    // },
    {
        id:203,
        label:'MENUITEMS.CLIENTE.TEXT',
        icon: 'bxs-user-rectangle',
        link: '/gestion/cliente/ver-clientes',
    },
    {
        id:204,
        label:'MENUITEMS.BODEGA.TEXT',
        icon: 'bx-bold',
        link: '/gestion/bodega/ver-bodegas',
    },
    {
        id:205,
        label:'MENUITEMS.CATEGORIA.TEXT',
        icon: 'bx-cube-alt',
        link: '/gestion/categoria/ver-categorias',
    },
    {
        id:206,
        label:'MENUITEMS.PRODUCTO.TEXT',
        icon: 'bxl-product-hunt',
        link: '/gestion/producto/ver-productos',
    },
];

