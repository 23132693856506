import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { CyptolandingComponent } from './cyptolanding/cyptolanding.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { InicioSesionGuard } from './guards/inicio-sesion.guard';
import { NoInicioSesionGuard } from './guards/no-inicio-sesion.guard';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  // { path: 'crypto-ico-landing', component: CyptolandingComponent },
  { path:'',
    canActivate:[NoInicioSesionGuard],
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  { path:'',
    component: LayoutComponent,
    canActivateChild:[InicioSesionGuard],
    children:[
      {
        path:'inicio',
        loadChildren: () => import('./components/inicio/inicio.module').then(m => m.InicioModule)
      },
      {
        path:'gestion',
        loadChildren: () => import('./components/gestion/gestion.module').then(m => m.GestionModule)
      }
    ]
  },

  // tslint:disable-next-line: max-line-length
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' ,useHash:true})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
